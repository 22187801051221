import Ratings from "react-ratings-declarative"
import React from "react"

const RatingComponent = ({ rating, size }) => {
  return (
    <Ratings
      rating={rating}
      widgetDimensions={size}
      widgetRatedColors="rgb(41, 51, 155)"
    >
      <Ratings.Widget />
      <Ratings.Widget />
      <Ratings.Widget />
      <Ratings.Widget />
      <Ratings.Widget />
    </Ratings>
  )
}
export default RatingComponent
